$primary: #9c93bb;
$primary-hover: #736c8a;

.ant-card {
  box-shadow: darkgray 1px -1px 6px;
}

.ant-pagination-item-active {
  color: #fff;
  background-color: $primary !important;
}

.ant-btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;

  &:hover {
    background-color: $primary-hover !important;
  }
  &:active {
    background-color: $primary-hover !important;
  }
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $primary-hover !important;
}